import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import ButtonSolid from "../Button/ButtonSolid";

function encode(data) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

const StyledForm = styled.div`
  [type="email"],
  [type="number"],
  [type="tel"],
  [type="text"],
  select,
  textarea {
    ${tw`text-sm w-full text-gray-700 bg-white px-4 py-4 border border-gray-200 rounded-lg focus:border-transparent focus:outline-none focus:ring-3 focus:ring-secondary-500 focus:ring-opacity-30 transition-colors duration-300 ease-linear`}
  }
`;

const Form = ({ answers }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    if (
      (answers != "" && state["answers"] == null) ||
      state["answers"] !== answers
    ) {
      setState((state) => ({
        ...state,
        answers: answers,
      }));
    }
  }, [state, answers]);

  const handleChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(
        () =>
          (document.getElementById("get-started-form-ajax-response").innerHTML =
            "Thank you for your submission!"),
        form.remove(),
        (window.dataLayer = window.dataLayer || []),
        window.dataLayer.push({
          event: "getStartedFormSubmission",
        })
      )
      .catch((error) => alert(error));
  };

  return (
    <StyledForm>
      <div id="get-started-form-ajax-response" className="text-gray-700"></div>
      <form
        name="Get Started"
        method="post"
        action=""
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Get Started" />
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </div>

        <div className="grid w-full grid-cols-1 md:grid-cols-2 md:gap-x-6">
          <div className="mb-5">
            <label
              className="mb-1 block font-semibold text-primary-900"
              htmlFor="name"
            >
              First Name
            </label>
            <input
              type="text"
              name="first-name"
              onChange={handleChange}
              required={true}
            />
          </div>
          <div className="mb-5">
            <label
              className="mb-1 block font-semibold text-primary-900"
              htmlFor="name"
            >
              Last Name
            </label>
            <input
              type="text"
              name="last-name"
              onChange={handleChange}
              required={true}
            />
          </div>
        </div>

        <div className="grid w-full grid-cols-1 md:grid-cols-2 md:gap-x-6">
          <div className="mb-5">
            <label
              className="mb-1 block font-semibold text-primary-900"
              htmlFor="phone"
            >
              Phone Number
            </label>
            <input
              type="tel"
              name="phone"
              onChange={handleChange}
              required={true}
            />
          </div>
          <div className="mb-5">
            <label
              className="mb-1 block font-semibold text-primary-900"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              required={true}
            />
          </div>
        </div>

        <div className="grid w-full grid-cols-1 md:grid-cols-2 md:gap-x-6">
          <div className="mb-6">
            <label
              className="mb-1 block font-semibold text-primary-900"
              htmlFor="city"
            >
              City
            </label>
            <input
              type="text"
              name="city"
              onChange={handleChange}
              required={true}
            />
          </div>

          <div className="mb-5">
            <label
              className="mb-1 block font-semibold text-primary-900"
              htmlFor="state"
            >
              State
            </label>
            <select
              name="state"
              className="w-full rounded-lg border border-gray-200 bg-white px-4 py-4 transition-all duration-300 ease-linear focus:border-transparent focus:outline-none focus:ring-3 focus:ring-secondary-500/30"
              onChange={handleChange}
              required={true}
            >
              <option value="">Select One...</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
        </div>

        <div className="mb-5">
          <label
            className="mb-1 block font-semibold text-primary-900"
            htmlFor="comments"
          >
            Comments
          </label>
          <textarea name="comments" onChange={handleChange} rows="4" />
        </div>

        <textarea name="answers" className="hidden" />

        <ButtonSolid type="submit" text="Send message" />
      </form>
    </StyledForm>
  );
};

export default Form;
