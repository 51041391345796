import React, { useState, useEffect } from "react";
// import { graphql } from "gatsby";
import { Switch } from "@headlessui/react";
import "jquery/dist/jquery.min.js";
import ReactBootstrapSlider from "react-bootstrap-slider";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import GetStarted from "../components/Form/GetStarted";
import ButtonSolid from "../components/Button/ButtonSolid";

const StyledSlider = styled.div`
  .slider {
    ${tw`w-full! h-auto! mb-14! mt-0.5 max-w-[507px]`}
  }
  .slider-track {
    ${tw`bg-gradient-to-r from-secondary-500 to-primary-500 rounded-lg top-0! m-0! h-2!`}
  }
  .slider-tick-container {
    ${tw`top-0.5!`}
  }
  .slider-tick {
    ${tw`w-1! h-1! bg-white/70! shadow-none! opacity-100! bg-none! border-none! m-0!`}
    &:last-child {
      ${tw`left-[99%]!`}
    }
  }
  .slider-tick-label-container {
    ${tw`m-0! relative top-6`}
  }
  .slider-tick-label {
    ${tw`text-2xs! text-gray-100! font-medium! w-auto! -ml-1.5!`}
    &:first-child {
      ${tw`left-[2%]!`}
    }
    &:last-child {
      ${tw`left-[99%]!`}
    }
  }
  .slider-handle {
    ${tw`w-8! h-8! bg-gray-100 bg-none! -top-3! z-10! flex justify-between items-center p-1`}
    &.hide {
      ${tw`hidden`}
    }
  }
  .tick-slider-selection {
    ${tw`bg-none! bg-transparent! shadow-none!`}
  }
  .tooltip-main {
    ${tw`absolute mt-10`}
    .tooltip-inner {
      ${tw`relative left-0! -ml-6! bg-gray-50! text-gray-500 font-bold px-2.5! py-1!`}
      font-size: 11px !important;
      line-height: 15px !important;
      &:after {
        content: "";
        ${tw`absolute -bottom-1.5 inset-x-0 mx-auto h-0 w-0 border-8 border-b-0 border-transparent border-t-white`}
      }
    }
  }
  .tooltip-min.bs-tooltip-top,
  .tooltip-max.bs-tooltip-top {
    ${tw`hidden`}
  }
`;

const Page = ({ data }) => {
  const invisible = "hidden";
  const visible = "block";

  const baseCost = 199;
  const [cost, setCost] = useState(0);
  const [costSummary, setCostSummary] = useState("");
  const [getStarted, setGetStarted] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [taxServicesEnabled, setTaxServicesEnabled] = useState(false);
  const [taxReturns, setTaxReturns] = useState(false);
  const [quarterlyTaxPlanning, setQuarterlyTaxPlanning] = useState(false);
  const [payroll, setPayroll] = useState(false);
  const [salesTax, setSalesTax] = useState(false);
  const [bookkeepingServicesEnabled, setBookkeepingServicesEnabled] =
    useState(false);
  const [basisAccounting, setBasisAccounting] = useState("Cash");
  const [frequency, setFrequency] = useState("Monthly");
  const [financialAccounts, setFinancialAccounts] = useState("<5");
  const [fractionalCFOEnabled, setFractionalCFOEnabled] = useState(false);
  const [thirdParty, setThirdParty] = useState(false);
  const [billPay, setBillPay] = useState(false);
  const [fractionalCFO, setFractionalCFO] = useState(false);
  const [inputValues, setInputValues] = useState(false);

  const changeSliderValue = (e) => {
    setSliderValue(e.target.value);
  };

  useEffect(() => {
    if (taxServicesEnabled === false) {
      setTaxReturns(false);
      setQuarterlyTaxPlanning(false);
      setPayroll(false);
      setSalesTax(false);
    }

    if (fractionalCFOEnabled === false) {
      setThirdParty(false);
      setBillPay(false);
      setFractionalCFO(false);
    }

    // 1st Breakpoint
    if (sliderValue >= 0 && sliderValue <= 10000) {
      setCost(baseCost);
    }

    // 2nd Breakpoint
    if (sliderValue >= 10001 && sliderValue <= 20000) {
      setCost(baseCost + 100);
    }

    // 3rd Breakpoint
    if (sliderValue >= 20001 && sliderValue <= 30000) {
      setCost(baseCost + 200);
    }

    // 4th Breakpoint
    if (sliderValue >= 30001 && sliderValue <= 60000) {
      setCost(baseCost + 400);
    }

    // 5th Breakpoint
    if (sliderValue >= 60001 && sliderValue <= 100000) {
      setCost(baseCost + 700);
    }

    // 6th Breakpoint
    if (sliderValue >= 100001 && sliderValue <= 150000) {
      setCost(baseCost + 1000);
    }

    // 7th Breakpoint
    if (sliderValue >= 150001 && sliderValue <= 200000) {
      setCost(baseCost + 1300);
    }

    // 8th Breakpoint
    if (sliderValue >= 200001 && sliderValue <= 249999) {
      setCost(baseCost + 1500);
    }

    // Additional Fees
    let finalCost = cost;

    if (financialAccounts === "5-9") {
      finalCost = finalCost + 125;
    } else if (financialAccounts === "10-15") {
      finalCost = finalCost + 250;
    }

    if (taxReturns) {
      finalCost = finalCost + 175;
    }

    if (quarterlyTaxPlanning) {
      finalCost = finalCost + 250;
    }

    if (payroll) {
      finalCost = finalCost + 100;
    }

    if (salesTax) {
      finalCost = finalCost + 50;
    }

    // Add ACCOUNTING BASIS and FREQUENCY discounts/additions
    if (sliderValue >= 0 && sliderValue <= 60000) {
      if (basisAccounting === "Cash" && frequency === "Quarterly") {
        finalCost = Math.round(finalCost - finalCost * 0.2);
      } else if (basisAccounting === "Cash" && frequency === "Annual") {
        finalCost = Math.round(finalCost - finalCost * 0.4);
      } else if (basisAccounting === "Accrual" && frequency === "Monthly") {
        finalCost = Math.round(finalCost + finalCost * 0.5);
      } else if (basisAccounting === "Accrual" && frequency === "Quarterly") {
        finalCost = Math.round(
          finalCost + finalCost * 0.5 - (finalCost + finalCost * 0.5) * 0.2
        );
      } else if (basisAccounting === "Accrual" && frequency === "Annual") {
        finalCost = Math.round(
          finalCost + finalCost * 0.5 - (finalCost + finalCost * 0.5) * 0.4
        );
      }
    }

    if (sliderValue >= 60001 && sliderValue <= 249999) {
      if (basisAccounting === "Cash" && frequency === "Quarterly") {
        finalCost = Math.round(finalCost - finalCost * 0.2);
      } else if (basisAccounting === "Cash" && frequency === "Annual") {
        finalCost = Math.round(finalCost - finalCost * 0.4);
      } else if (basisAccounting === "Accrual" && frequency === "Monthly") {
        finalCost = Math.round(finalCost * 2);
      } else if (basisAccounting === "Accrual" && frequency === "Quarterly") {
        finalCost = Math.round(finalCost * 2 - finalCost * 2 * 0.2);
      } else if (basisAccounting === "Accrual" && frequency === "Annual") {
        finalCost = Math.round(finalCost * 2 - finalCost * 2 * 0.4);
      }
    }

    // Get Started Button
    if (
      finalCost > 0 ||
      financialAccounts === "15+" ||
      thirdParty ||
      payroll ||
      billPay ||
      fractionalCFO
    ) {
      setGetStarted(true);
    } else {
      setGetStarted(false);
    }

    // Price Summary
    if (
      sliderValue >= 250000 ||
      financialAccounts === "15+" ||
      thirdParty ||
      payroll ||
      billPay ||
      fractionalCFO
    ) {
      setCostSummary(
        <div className="text-2xl font-bold md:text-4xl">
          Contact us for a Custom Quote
        </div>
      );
    } else {
      setCostSummary([
        "$" + finalCost.toLocaleString("en-US"),
        <span className="ml-1.5 text-base font-normal">/mo</span>,
      ]);
    }

    setInputValues(
      "Quote: " +
        (sliderValue >= 250000 ||
        financialAccounts === "15+" ||
        thirdParty ||
        payroll ||
        billPay ||
        fractionalCFO
          ? "Contact us for a Custom Quote"
          : "$" + finalCost.toLocaleString("en-US") + "/mo") +
        "\n" +
        "Monthly Expenses: " +
        "$" +
        sliderValue.toLocaleString("en-US") +
        "\n" +
        "Tax Returns: " +
        (taxReturns ? "Yes" : "No") +
        "\n" +
        "Quarterly Tax Planning: " +
        (quarterlyTaxPlanning ? "Yes" : "No") +
        "\n" +
        "Payroll: " +
        (payroll ? "Yes" : "No") +
        "\n" +
        "Sales Tax, Property Tax & 1099s: " +
        (salesTax ? "Yes" : "No") +
        "\n" +
        "Basis of Accounting: " +
        basisAccounting +
        "\n" +
        "Frequency: " +
        frequency +
        "\n" +
        "Financial Accounts: " +
        financialAccounts +
        "\n" +
        "Third-Party App Integrations: " +
        (thirdParty ? "Yes" : "No") +
        "\n" +
        "Bill-pay: " +
        (billPay ? "Yes" : "No") +
        "\n" +
        "Fractional CFO/Controller: " +
        (fractionalCFO ? "Yes" : "No") +
        "\n"
    );
  }, [
    cost,
    sliderValue,
    taxReturns,
    quarterlyTaxPlanning,
    payroll,
    salesTax,
    basisAccounting,
    frequency,
    financialAccounts,
    thirdParty,
    billPay,
    fractionalCFO,
    taxServicesEnabled,
    fractionalCFOEnabled,
    inputValues,
  ]);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Bookkeeping and Accounting Services | DeBlanc + Murphy"
        description="Get a free estimate for bookkeeping, tax & outsourced CFO services. We serve businesses & individuals. Learn more now!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pb-20 pt-10 md:pb-32 md:pt-16">
        <div className="container">
          <header className="mb-10 max-w-[720px] md:mb-16">
            <div className={` ${formVisible ? invisible : visible}`}>
              <h1>Bookkeeping & Accounting Services</h1>
            </div>

            <div className={` ${formVisible ? visible : invisible}`}>
              <div className="heading-one">Get Started</div>
            </div>

            <p className="md:text-xl">
              {formVisible
                ? "Please provide your contact information and a DeBlanc, Murphy & Murphy team member will be in touch with you shortly."
                : "Tell us what services you’re looking for and get an instant estimate of the costs."}
            </p>
          </header>

          <div className="grid md:grid-cols-12 md:gap-x-10">
            <div className="md:col-span-7 md:col-start-1">
              <div
                className={`rounded-3xl border border-primary-500 bg-gray-800 px-6 py-8 md:p-10 ${
                  formVisible ? visible : invisible
                }`}
              >
                <GetStarted answers={inputValues} />
              </div>

              <div
                className={`space-y-8 rounded-3xl border border-primary-500 bg-gray-800 px-6 py-8 md:space-y-10 md:p-10 ${
                  formVisible ? invisible : visible
                }`}
              >
                <div>
                  <div className="flex items-center justify-between space-x-4">
                    <div className="font-heading text-xl font-bold text-white md:text-3xl">
                      Bookkeeping
                    </div>

                    <div>
                      <Switch
                        checked={bookkeepingServicesEnabled}
                        onChange={setBookkeepingServicesEnabled}
                        className={`relative inline-flex h-[30px] w-[58px] flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 ${
                          bookkeepingServicesEnabled
                            ? "bg-gradient-to-r from-secondary-500 to-primary-500"
                            : "bg-typography-body"
                        }`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`pointer-events-none inline-block h-6 w-6 translate-y-[3px] transform rounded-full bg-white shadow-xl ring-0 transition duration-300 ease-linear ${
                            bookkeepingServicesEnabled
                              ? "translate-x-[30px]"
                              : "translate-x-1"
                          }`}
                        />
                      </Switch>
                    </div>
                  </div>

                  <div
                    className={`pt-5 ${
                      bookkeepingServicesEnabled ? visible : invisible
                    }`}
                  >
                    <div>
                      <div className="mb-12 text-sm font-light uppercase tracking-wide text-typography-heading/70">
                        Monthly Expenses
                      </div>
                      <StyledSlider>
                        <ReactBootstrapSlider
                          value={sliderValue}
                          step={500}
                          change={changeSliderValue}
                          orientation="horizontal"
                          ticks={[
                            0, 10000, 20000, 30000, 60000, 100000, 150000,
                            200000, 250000,
                          ]}
                          ticks_labels={[
                            "0",
                            "10K",
                            "20k",
                            "30K",
                            "60K",
                            "100K",
                            "150K",
                            "200K",
                            "250K+",
                          ]}
                          ticks_positions={[
                            0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5, 100,
                          ]}
                          formatter={function (value) {
                            return "$" + value.toLocaleString("en-US");
                          }}
                        />
                      </StyledSlider>
                    </div>

                    <div className="space-y-5">
                      <fieldset>
                        <legend className="mb-1.5 text-sm font-light uppercase tracking-wide text-typography-heading/70">
                          Accounting Basis
                        </legend>

                        <div className="inline-flex space-x-4 md:space-x-6">
                          <label className="relative cursor-pointer pl-8 text-sm font-semibold text-white md:text-base">
                            <input
                              type="radio"
                              onChange={() => setBasisAccounting("Cash")}
                              checked={basisAccounting === "Cash"}
                            />
                            Cash
                            <span className="pricing-tool-radio"></span>
                          </label>

                          <label className="relative cursor-pointer pl-8 text-sm font-semibold text-white md:text-base">
                            <input
                              type="radio"
                              onChange={() => setBasisAccounting("Accrual")}
                              checked={basisAccounting === "Accrual"}
                            />
                            Accrual
                            <span className="pricing-tool-radio"></span>
                          </label>
                        </div>
                      </fieldset>

                      <fieldset>
                        <legend className="mb-1.5 text-sm font-light uppercase tracking-wide text-typography-heading/70">
                          Frequency
                        </legend>

                        <div className="inline-flex space-x-4 md:space-x-6">
                          <label className="relative cursor-pointer pl-8 text-sm font-semibold text-white md:text-base">
                            <input
                              type="radio"
                              onChange={() => setFrequency("Monthly")}
                              checked={frequency === "Monthly"}
                            />
                            Monthly
                            <span className="pricing-tool-radio"></span>
                          </label>

                          <label className="relative cursor-pointer pl-8 text-sm font-semibold text-white md:text-base">
                            <input
                              type="radio"
                              onChange={() => setFrequency("Quarterly")}
                              checked={frequency === "Quarterly"}
                            />
                            Quarterly
                            <span className="pricing-tool-radio"></span>
                          </label>

                          <label className="relative cursor-pointer pl-8 text-sm font-semibold text-white md:text-base">
                            <input
                              type="radio"
                              onChange={() => setFrequency("Annual")}
                              checked={frequency === "Annual"}
                            />
                            Annual
                            <span className="pricing-tool-radio"></span>
                          </label>
                        </div>
                      </fieldset>

                      <fieldset>
                        <legend className="mb-1.5 text-sm font-light uppercase tracking-wide text-typography-heading/70">
                          Financial Accounts
                        </legend>

                        <div className="inline-flex space-x-4 md:space-x-6">
                          <label className="relative cursor-pointer pl-8 text-sm font-semibold text-white md:text-base">
                            <input
                              type="radio"
                              onChange={() => setFinancialAccounts("<5")}
                              checked={financialAccounts === "<5"}
                            />
                            {`<5`}
                            <span className="pricing-tool-radio"></span>
                          </label>

                          <label className="relative cursor-pointer pl-8 text-sm font-semibold text-white md:text-base">
                            <input
                              type="radio"
                              onChange={() => setFinancialAccounts("5-9")}
                              checked={financialAccounts === "5-9"}
                            />
                            5-9
                            <span className="pricing-tool-radio"></span>
                          </label>

                          <label className="relative cursor-pointer pl-8 text-sm font-semibold text-white md:text-base">
                            <input
                              type="radio"
                              onChange={() => setFinancialAccounts("10-15")}
                              checked={financialAccounts === "10-15"}
                            />
                            10-15
                            <span className="pricing-tool-radio"></span>
                          </label>

                          <label className="relative cursor-pointer pl-8 text-sm font-semibold text-white md:text-base">
                            <input
                              type="radio"
                              onChange={() => setFinancialAccounts("15+")}
                              checked={financialAccounts === "15+"}
                            />
                            15+
                            <span className="pricing-tool-radio"></span>
                          </label>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between space-x-4">
                    <div className="font-heading text-xl font-bold text-white md:text-3xl">
                      Tax Services
                    </div>

                    <div>
                      <Switch
                        checked={taxServicesEnabled}
                        onChange={setTaxServicesEnabled}
                        className={`relative inline-flex h-[30px] w-[58px] flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 ${
                          taxServicesEnabled
                            ? "bg-gradient-to-r from-secondary-500 to-primary-500"
                            : "bg-typography-body"
                        }`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`pointer-events-none inline-block h-6 w-6 translate-y-[3px] transform rounded-full bg-white shadow-xl ring-0 transition duration-300 ease-linear ${
                            taxServicesEnabled
                              ? "translate-x-[30px]"
                              : "translate-x-1"
                          }`}
                        />
                      </Switch>
                    </div>
                  </div>

                  <div
                    className={`pt-3.5 ${
                      taxServicesEnabled ? visible : invisible
                    }`}
                  >
                    <fieldset>
                      <legend className="mb-1.5 text-sm font-light uppercase tracking-wide text-typography-heading/70">
                        Tax Returns
                      </legend>

                      <div className="space-y-3">
                        <div>
                          <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                            <input
                              type="checkbox"
                              onChange={() => setTaxReturns(!taxReturns)}
                              checked={taxReturns === true}
                            />
                            <span className="font-semibold text-white">
                              Tax Returns
                            </span>
                            <div className="group ml-2">
                              {/* <i className="fa-light fa-circle-info text-white/70"></i>
                              <div className="invisible absolute bottom-6 -left-2 z-10 w-[240px] rounded-sm bg-gray-900 px-3.5 py-3 text-xs text-white opacity-0 transition duration-300 ease-linear group-hover:visible group-hover:opacity-100">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi.
                  </div>*/}
                            </div>
                            <span className="pricing-tool-checkbox"></span>
                          </label>
                        </div>

                        <div>
                          <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setQuarterlyTaxPlanning(!quarterlyTaxPlanning)
                              }
                              checked={quarterlyTaxPlanning === true}
                            />
                            <span className="font-semibold text-white">
                              Quarterly Tax Planning
                            </span>
                            <div className="group ml-2">
                              {/*  <i className="fa-light fa-circle-info text-white/70"></i>
                              <div className="invisible absolute bottom-6 -left-2 z-10 w-[240px] rounded-sm bg-gray-900 px-3.5 py-3 text-xs text-white opacity-0 transition duration-300 ease-linear group-hover:visible group-hover:opacity-100">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi.
                              </div>*/}
                            </div>
                            <span className="pricing-tool-checkbox"></span>
                          </label>
                        </div>

                        <div>
                          <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                            <input
                              type="checkbox"
                              onChange={() => setSalesTax(!salesTax)}
                              checked={salesTax === true}
                            />
                            <span className="font-semibold text-white">
                              Sales Tax, Property Tax & 1099s
                            </span>
                            <div className="group ml-2">
                              {/* <i className="fa-light fa-circle-info text-white/70"></i>
                              <div className="invisible absolute bottom-6 -left-2 z-10 w-[240px] rounded-sm bg-gray-900 px-3.5 py-3 text-xs text-white opacity-0 transition duration-300 ease-linear group-hover:visible group-hover:opacity-100">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi.
                              </div>*/}
                            </div>
                            <span className="pricing-tool-checkbox"></span>
                          </label>
                        </div>

                        <div>
                          <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                            <input
                              type="checkbox"
                              onChange={() => setPayroll(!payroll)}
                              checked={payroll === true}
                            />
                            <span className="font-semibold text-white">
                              Payroll
                            </span>
                            <div className="group ml-2">
                              {/*  <i className="fa-light fa-circle-info text-white/70"></i>
                              <div className="invisible absolute bottom-6 -left-2 z-10 w-[240px] rounded-sm bg-gray-900 px-3.5 py-3 text-xs text-white opacity-0 transition duration-300 ease-linear group-hover:visible group-hover:opacity-100">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi.
                              </div>*/}
                            </div>
                            <span className="pricing-tool-checkbox"></span>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between space-x-4">
                    <div className="font-heading text-xl font-bold text-white md:text-3xl">
                      Outsourced CFO Services
                    </div>

                    <div>
                      <Switch
                        checked={fractionalCFOEnabled}
                        onChange={setFractionalCFOEnabled}
                        className={`relative inline-flex h-[30px] w-[58px] flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 ${
                          fractionalCFOEnabled
                            ? "bg-gradient-to-r from-secondary-500 to-primary-500"
                            : "bg-typography-body"
                        }`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`pointer-events-none inline-block h-6 w-6 translate-y-[3px] transform rounded-full bg-white shadow-xl ring-0 transition duration-300 ease-linear ${
                            fractionalCFOEnabled
                              ? "translate-x-[30px]"
                              : "translate-x-1"
                          }`}
                        />
                      </Switch>
                    </div>
                  </div>

                  <div
                    className={`pt-3.5 ${
                      fractionalCFOEnabled ? visible : invisible
                    }`}
                  >
                    <fieldset>
                      <div className="space-y-3">
                        <div>
                          <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                            <input
                              type="checkbox"
                              onChange={() => setThirdParty(!thirdParty)}
                              checked={thirdParty === true}
                            />
                            <span className="font-semibold text-white">
                              Third-Party App Integrations
                            </span>
                            <div className="group ml-2">
                              {/*  <i className="fa-light fa-circle-info text-white/70"></i>
                              <div className="invisible absolute bottom-6 -left-2 z-10 w-[240px] rounded-sm bg-gray-900 px-3.5 py-3 text-xs text-white opacity-0 transition duration-300 ease-linear group-hover:visible group-hover:opacity-100">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi.
                              </div>*/}
                            </div>
                            <span className="pricing-tool-checkbox"></span>
                          </label>
                        </div>

                        <div>
                          <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                            <input
                              type="checkbox"
                              onChange={() => setBillPay(!billPay)}
                              checked={billPay === true}
                            />
                            <span className="font-semibold text-white">
                              Bill-pay
                            </span>
                            <span className="pricing-tool-checkbox"></span>
                          </label>
                        </div>

                        <div>
                          <label className="relative inline-flex cursor-pointer items-center pl-8 text-sm md:text-base">
                            <input
                              type="checkbox"
                              onChange={() => setFractionalCFO(!fractionalCFO)}
                              checked={fractionalCFO === true}
                            />
                            <span className="font-semibold text-white">
                              Fractional CFO/Controller
                            </span>
                            <span className="pricing-tool-checkbox"></span>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:col-span-5 md:col-end-13">
              <div className="fixed bottom-0 left-0 z-[99] w-full border border-primary-500 bg-gray-800 p-4 text-center md:sticky md:top-32 md:z-0 md:rounded-3xl md:p-8">
                <div className="heading-three mb-1 text-primary-500">
                  Your Estimate
                </div>
                <div className="mb-2.5 flex items-center justify-center md:mb-5">
                  <span className="text-2xl font-bold text-white md:text-4xl md:text-[40px] md:leading-[40px]">
                    {costSummary}
                  </span>
                </div>
                <p
                  className={`hidden text-sm md:block ${formVisible && "mb-0"}`}
                >
                  This quote may not include all elements of a potential
                  engagement, and is meant to give a ballpark figure based on
                  the information you provide.
                </p>
                <ButtonSolid
                  text="Let's Go"
                  onClick={setFormVisible}
                  className={`w-full md:max-w-[284px] ${
                    getStarted === false && "pointer-events-none bg-primary-500"
                  } ${formVisible && "hidden"}`}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

// export const data = graphql`
//   {
//     openGraphImage: file(
//       relativePath: { eq: "open-graph/facebook/Global.jpg" }
//     ) {
//       publicURL
//     }
//     twitterOpenGraphImage: file(
//       relativePath: { eq: "open-graph/twitter/Global.jpg" }
//     ) {
//       publicURL
//     }
//   }
// `;

export default Page;
